import * as React from "react"
import {graphql} from "gatsby";
import InnerLayout from "../components/global/innerLayout"
import LargeCarousel from "../components/global/largeCarousel";
import Section2 from "../components/publishing-home/section-2"
import Section3 from "../components/publishing-home/section-3"
import Section4 from "../components/publishing-home/section-4"
import Section5 from "../components/publishing-home/section-5"
import Section6 from "../components/publishing-home/section-6"

export const query = graphql`
    query($publishedDateFilter: [Craft_QueryArgument]) {
      craft {
        page: entry(section: "publishingHome") {
          ... on Craft_publishingHome_publishingHome_Entry {
          seomatic {
              metaTitleContainer
              metaTagContainer
              metaLinkContainer
              metaScriptContainer
              metaJsonLdContainer
              metaSiteVarsContainer
              frontendTemplateContainer
            }
            id
            title
            publishingHomeLargeCarousel {
              ... on Craft_publishingHomeLargeCarousel_slide_BlockType {
                image {
                  url(transform: "largeCarouselTransform")
                  ... on Craft_websitePublishingCarousel_Asset {
                    altText
                  }
                }
                imageLink {
                  linkUrl
                  openInNewTab
                }
              }
            }
            publishingHomeSection2ImageLinkWithTitle {
              ... on Craft_publishingHomeSection2ImageLinkWithTitle_link_BlockType {
                heading
                image {
                  url(transform: "mediumHorizontal32AspectRatioTransform")
                  ... on Craft_websitePublishingHome_Asset {
                    altText
                  }
                }
                imageLink {
                  linkType
                  linkUrl
                  openInNewTab
                }
              }
            }
            section3ContentTitle
            section3TextContentWithLink
            section3ButtonLink {
                linkUrl
                openInNewTab
                linkText
            }
            section3ImageLink {
                linkUrl
                openInNewTab
            }
            section3ImageLeft
            publishingHomeSection3Image {
                url(transform: "mediumHorizontal32AspectRatioTransform")
                ... on Craft_websitePublishingHome_Asset {
                    altText
                  }
            }
            section4ContentTitle
            section5ContentTitle
            section5ButtonLink {
                    linkUrl
                    openInNewTab
                    linkText
                }
            publishingHomeLargeCarouselSecondary {
              ... on Craft_publishingHomeLargeCarouselSecondary_slide_BlockType {
                image {
                  url(transform: "largeCarouselTransform")
                  ... on Craft_websitePublishingCarousel_Asset {
                    altText
                  }
                }
                imageLink {
                  linkUrl
                  openInNewTab
                }
              }
            }
            section6ContentTitle
            section6ButtonLink {
              linkUrl
              openInNewTab
              linkText
            }
          }
        }
        latestBooks: entries(
            section: "bookPage"
            publishedDate: $publishedDateFilter
            limit: 20
            orderBy: "publishedDate desc, title"
        ) {
          ... on Craft_bookPage_default_Entry {
            key:id
            url
            title
            bookTitlePrepend
            bookFrontCover {
                url(transform: "bookCoverLarge")
                ... on Craft_websiteBookPage_Asset {
                    altText
                }
            }
          }
        }
        freeStories: entries(
          section: "freeStoryPage"
          includeInFreeStories: true
          newsletterPublishDate: $publishedDateFilter
          limit: 3
          orderBy: "newsletterPublishDate desc, title"
        ) {
          ... on Craft_freeStoryPage_default_Entry {
            id
            slug
            title
            book {
              ... on Craft_bookPage_default_Entry {
                title
                bookTitlePrepend
              }
            }
          }
        }
      }
    }
`

const IndexPage = ({ data, pageContext }) => {

    return (
        <InnerLayout seomatic={data.craft.page.seomatic} entryId={data.craft.page.id}>
            <LargeCarousel slides={data.craft.page.publishingHomeLargeCarousel} items={1} loop={true} autoplay={true} autoplayTimeout={5000} autoplayHoverPause={true}></LargeCarousel>
            <Section2 data={data}/>
            <Section3 data={data}/>
            <Section4 data={data}/>
            <Section5 data={data}/>
            <Section6 data={data}/>
        </InnerLayout>
    )
}

export default IndexPage
