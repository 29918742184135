import * as React from 'react'
import Button from "../global/button"
import ImageLink from "../global/imageLink"
import "./section-2.scss"

const Section2 = (props) => {
    
    const getBookPosts = (nodes) => {
        return nodes.map(node => (
            <div className="book-post">
                <div className="post-img">
                    <ImageLink src={node.image[0].url} alt={node.image[0].altText} to={node.imageLink[0].linkUrl} openInNewTab={node.imageLink[0].openInNewTab}></ImageLink>
                </div>
                <Button classes="btn-primary" to={node.imageLink[0].linkUrl} text={node.heading} openInNewTab={node.imageLink[0].openInNewTab}></Button>
            </div>
        ))
    }

    return (
        <div className="book-block">
            <div className="container-small">
                <div className="book-post-row">
                    {getBookPosts(props.data.craft.page.publishingHomeSection2ImageLinkWithTitle)}
                </div>
            </div>
        </div>
    )
}

export default Section2