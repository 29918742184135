import * as React from 'react'
import Button from "../global/button"
import ImageLink from "../global/imageLink"
import parse from "html-react-parser";
import "./section-3.scss"

const Section3 = (props) => {

    const getContent = () => {
        return (
            <>
                <h3>{props.data.craft.page.section3ContentTitle}</h3>
                {parse(props.data.craft.page.section3TextContentWithLink)}
                <Button classes="btn-primary" to={props.data.craft.page.section3ButtonLink[0].linkUrl} text={props.data.craft.page.section3ButtonLink[0].linkText} openInNewTab={props.data.craft.page.section3ButtonLink[0].openInNewTab}></Button>
            </>
        )
    }

    const getImage = () => {
        return (
            <ImageLink src={props.data.craft.page.publishingHomeSection3Image[0].url} alt={props.data.craft.page.publishingHomeSection3Image[0].altText} to={props.data.craft.page.section3ImageLink[0].linkUrl} openInNewTab={props.data.craft.page.section3ImageLink[0].openInNewTab}></ImageLink>
        )
    }

    return (
        <div className="chicken-book">
            <div className="container-small">
                <div className="post-block">
                    <div className="post-block-left">
                        {props.data.craft.page.section3ImageLeft === true ? (
                            getImage()
                        ):(
                            getContent()
                        )}
                    </div>
                    <div className="post-block-right">
                        {props.data.craft.page.section3ImageLeft === true ? (
                            getContent()
                        ):(
                            getImage()
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section3