import * as React from 'react'
import Button from "../global/button"
import "./section-6.scss"

const Section6 = (props) => {

    const getFreeStories = () => {
        return props.data.craft.freeStories.map(node => (
            <div className="stories-post">
                <h3><a href={`/story/${node.id}/${node.slug}`}>{node.title}</a></h3>
                <p>From: {node.book[0].bookTitlePrepend} {node.book[0].title}</p>
            </div>
        ))
    }

    return (
        <div className="free-stories">
            <h2>{props.data.craft.page.section6ContentTitle}</h2>
            <div className="container-small">
                <div className="stories-row">
                    {getFreeStories()}
                </div>
                <div className="more-btn">
                    <Button classes="btn-primary" to={props.data.craft.page.section6ButtonLink[0].linkUrl} text={props.data.craft.page.section6ButtonLink[0].linkText} openInNewTab={props.data.craft.page.section6ButtonLink[0].openInNewTab}></Button>
                </div>
            </div>
        </div>
    )
}

export default Section6