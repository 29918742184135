import * as React from 'react'
import ImageLink from '../global/imageLink'
import loadable from '@loadable/component'
import defaultFrontCover from "../../images/coming-soon-front-cover.png"
import '../owl-carousel/scss/owl.carousel.scss'
import "./latestBooksCarousel.scss"

const OwlCarousel = loadable(() => import('react-owl-carousel'), { ssr: false });

const LatestBooksCarousel = (props) => {

    const getBookFrontCover = (node) => {
        if (node.bookFrontCover.length > 0) {
            return node.bookFrontCover[0].url
        } else {
            return defaultFrontCover
        }
    }

    const getSlides = () => {
        return props.books.map(node => (
            <div className="latest-post">
                <div className="latest-post-img">
                    <ImageLink src={getBookFrontCover(node)} alt={node.altText} to={node.url}></ImageLink>
                </div>
                <h4><a href={node.url}>{node.bookTitlePrepend} {node.title}</a></h4>
            </div>
        ))
    }

    const carouselResponse = {
        0:{
            items:2
        },
        600:{
            items:3
        },
        1000:{
            items:5
        }
    }

    return (
        <div class="latest-slider">
            <OwlCarousel loop={false} autoplay={false} responsive={carouselResponse} dot={false} nav={true} margin={16}>
                { getSlides() }
            </OwlCarousel>
        </div>
    )
}

export default LatestBooksCarousel