import * as React from 'react'
import LatestBooksCarousel from "../global/latestBooksCarousel"
import "./section-4.scss"

const Section4 = (props) => {
    return (
        <div className="latest-collection">
            <div className="container-small">
                <h2>{props.data.craft.page.section4ContentTitle}</h2>
                <LatestBooksCarousel books={props.data.craft.latestBooks}/>
            </div>
        </div>
    )
}

export default Section4