import * as React from 'react'
import Button from "../global/button"
import LargeCarousel from "../global/largeCarousel"
import "./section-5.scss"

const Section5 = (props) => {

    return (
        <>
            <LargeCarousel slides={props.data.craft.page.publishingHomeLargeCarouselSecondary} items={1} loop={true} autoplay={true} autoplayTimeout={5000} autoplayHoverPause={true}></LargeCarousel>
            <div className="soul-education">
                <div className="container">
                    <h2>{props.data.craft.page.section5ContentTitle}</h2>
                    <Button classes="btn-primary" to={props.data.craft.page.section5ButtonLink[0].linkUrl} text={props.data.craft.page.section5ButtonLink[0].linkText} openInNewTab={props.data.craft.page.section5ButtonLink[0].openInNewTab}></Button>
                </div>
            </div>
        </>

    )
}

export default Section5